import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import './CatalogDownloadHandler.css';

const CatalogDownloadHandler = ({ card, catalogUrl, onSendSuccess }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');

  // Функция для получения clientId из Google Analytics 4
  const getClientId = () => {
    return new Promise((resolve, reject) => {
      if (window.gtag) {
        window.gtag('get', 'G-9BWKKXMMQW', 'client_id', (clientId) => {
          if (clientId) {
            resolve(clientId);
          } else {
            reject('Client ID not available');
          }
        });
      } else {
        reject('Google Analytics is not initialized');
      }
    });
  };

  const handleDownloadClick = async (e) => {
    e.preventDefault(); // Предотвращаем действие по умолчанию

    if (!phoneNumber || phoneNumber.includes('_')) {
      setError('Пожалуйста, введите корректный номер телефона.');
      return;
    }

    try {
      // Получение clientId с помощью gtag в GA4
      const clientId = await getClientId();

      const data = {
        phoneNumber: phoneNumber,
        name: name,
        clientId: clientId || 'Неизвестно'
        // card: card, // Раскомментируйте, если нужно передать card
      };

      // Отправка данных
      const response = await fetch('https://muraestate.ru/api/amocrm', { // Замените на ваш URL
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        if (onSendSuccess) {
          onSendSuccess(); // Вызовем функцию при успешной отправке
        }
      } else {
        console.error('Ошибка при отправке данных');
      }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  };

  return (
    <div className="form">
      <div className="input-form">
        <InputMask
          mask="+7 (999) 999-99-99"
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
            setError(''); // Сброс ошибки при вводе телефона
          }}
          className="modal-input"
          placeholder="+7 (999) 999-99-99"
        />
        <input
          type="text"
          placeholder="Ваше имя"
          className="modal-input"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      {error && <div className="error-message">{error}</div>}
      <button onClick={handleDownloadClick} className="download-btn">
        Оставить заявку
      </button>
    </div>
  );
};

export default CatalogDownloadHandler;
