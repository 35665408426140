import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Link, useNavigate } from 'react-router-dom';
import './ModalCallback.css';

const ModalCallback = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    phoneNumber: '',
    topic: 'Новостройки'
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
    if (name === 'phoneNumber' && error) {
      setError(''); // Убираем ошибку при изменении телефона
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.phoneNumber || formData.phoneNumber.includes('_')) {
      setError('Пожалуйста, введите корректный номер телефона.');
      return;
    }
    alert(`Заявка отправлена: ${formData.name}, ${formData.phoneNumber}, ${formData.topic}`);
    onClose();
  };

  const handlePrivacyLinkClick = (e) => {
    e.preventDefault(); // Предотвращаем переход по ссылке
    onClose(); // Закрываем модальное окно
    navigate('/privacy-policy'); // Перенаправляем на страницу политики конфиденциальности
  };

  return (
    <div className="modal-call-back-overlay" onClick={onClose}>
      <div className="modal-call-back-container" onClick={(e) => e.stopPropagation()}>
        <button className="modal-call-back-close-button" onClick={onClose}>×</button>
        <div className="modal-call-back-content-left">
          <h2 className="modal-call-back-title">Закажите обратный звонок</h2>
          <p className="modal-call-back-description">
            Заполните форму, и мы свяжемся с вами в ближайшее время для консультации.
          </p>
        </div>
        <div className="modal-call-back-content-right">
          <form onSubmit={handleSubmit} className="modal-call-back-form">
            <div className="modal-call-back-input-form">
              <InputMask
                mask="+7 (999) 999-99-99"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="modal-call-back-input"
                placeholder="+7 (999) 999-99-99"
              />
              <input
                type="text"
                name="name"
                placeholder="Ваше имя"
                className="modal-call-back-input"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <select
              name="topic"
              value={formData.topic}
              onChange={handleChange}
              className="modal-call-back-input"
            >
              <option value="Новостройки">Новостройки</option>
              <option value="Ипотека">Ипотека</option>
            </select>
            {error && <div className="modal-call-back-error-message">{error}</div>}
            <button type="submit" className="modal-call-back-submit-btn">
              Отправить заявку
            </button>
            <p className="modal-call-back-note">
              *Оставляя заявку на сайте, вы соглашаетесь с нашей{' '}
              <Link
                to="/privacy-policy"
                className="modal-call-back-privacy-link"
                onClick={handlePrivacyLinkClick}
              >
                Политикой конфиденциальности
              </Link>.
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ModalCallback;
