import React, { useState, useEffect } from 'react';
import './RippleEffect.css';

const RippleEffect = () => {
  const [ripples, setRipples] = useState([]);

  useEffect(() => {
    const createRipple = () => {
      // Генерируем случайные координаты для ряби внутри карты
      const x = Math.random() * 100;
      const y = Math.random() * 100;
      
      const newRipple = { x, y, id: Date.now() };

      setRipples((prevRipples) => [...prevRipples, newRipple]);

      // Удаляем рябь через 1 секунду
      setTimeout(() => {
        setRipples((prevRipples) => prevRipples.filter((ripple) => ripple.id !== newRipple.id));
      }, 1000);
    };

    // Создаем рябь каждые 2.5 секунды
    const interval = setInterval(createRipple, 2500);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {ripples.map((ripple) => (
        <span
          key={ripple.id}
          className="ripple"
          style={{
            left: `${ripple.x}%`,
            top: `${ripple.y}%`,
          }}
        />
      ))}
    </>
  );
};

export default RippleEffect;
