import React, { useState } from 'react';
import './Footer.css';
import Logo from '../../logo.png';
import { FaInstagram, FaTelegram, FaYoutube } from 'react-icons/fa';
import ModalCallback from '../Modal/ModalCallback/ModalCallback.jsx';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-left">
            <a href="/">
              <img src={Logo} alt="Mura Estate" className="footer-logo" />
            </a>
            <div className="social-icons">
              <FaTelegram className="icon" />
              <FaYoutube className="icon" />
              <FaInstagram className="icon" />
            </div>
            {/* Новый блок с информацией */}
            <div className="footer-info">
              <p>
                Вся информация, представленная на данном сайте, носит исключительно информационный характер
                и не является публичной офертой, определяемой положениями статьи 437 Гражданского кодекса
                Российской Федерации.
              </p>
            </div>
          </div>

          <div className="footer-contact">
            <p><a href="tel:+74952751211">+7 (495) 275-12-11</a></p>
            <p><a href="mailto:ask@muraestate.ru">ask@muraestate.ru</a></p>
            <button className="contact-button" onClick={openModal}>Позвоните мне</button>
          </div>
        </div>
        <div className="footer-bottom">
          <p>© 2024 Mura Estate</p>
          <p>
            <a href="/privacy-policy">Политика конфиденциальности</a>
          </p>
        </div>
      </footer>

      {isModalOpen && <ModalCallback onClose={closeModal} />}
    </>
  );
};

export default Footer;
