import React, { useState, useRef } from 'react';
import './ModalContent.css';
import CatalogDownloadHandler from './CatalogDownloadHandler/CatalogDownloadHandler';
import { Link } from 'react-router-dom';
import PlayBtn from '../../../assets/modal/play.png';

const ModalContent = ({ card, onClose }) => {
  const [isSent, setIsSent] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [progress, setProgress] = useState(0); // Прогресс видео
  const videoRef = useRef(null);

  const handleSendSuccess = () => {
    setIsSent(true);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 5000);
  };

  const toggleVideoPlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
        videoRef.current.muted = !isMuted;
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const progressValue = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progressValue);
    }
  };

  const handleVideoEnded = () => {
    setIsPlaying(false);
  };

  return (
    <div className="modal-overlay-content">
      <button className="modal-close-button" onClick={onClose}>×</button>
      {!isSent ? (
        <>
          <div className="modal-content-left">
            <img 
              src={card.catalogImage} 
              alt="ЖК" 
              className="jk-image"
            />
            <div className="form-container">
              <p className="modal-description">
                Оставьте ваш номер, и мы свяжемся с вами для предоставления информации.
              </p>
              <CatalogDownloadHandler 
                card={card} 
                catalogUrl='https://storage.yandexcloud.net/bytehub/real-estate/pdf/sample-pdf.pdf'
                onSendSuccess={handleSendSuccess} 
              />
              <p className="modal-note">
                *Оставляя заявку на сайте, вы соглашаетесь с нашей <Link to="/privacy-policy">Политикой конфиденциальности</Link>.
              </p>
            </div>
          </div>
          <div className="modal-content-right">
            <div className="video-container" onClick={toggleVideoPlay}>
              { !isPlaying && (
                <div className="video-overlay">
                  <button className="play-button">
                    <img src={PlayBtn} alt="Play" />
                  </button>
                </div>
              )}
              <video 
                ref={videoRef}
                className="catalog-video"
                src={card.avatarUrl}
                onTimeUpdate={handleTimeUpdate} 
                onEnded={handleVideoEnded}
                playsInline
              />
              <div className="progress-bar" style={{ width: `${progress}%` }} />
            </div>
          </div>
        </>
      ) : (
        <div className="modal-success-message">
          Удачного просмотра! Окно закроется через 5 секунд.
        </div>
      )}
    </div>
  );
};

export default ModalContent;
