import React, { useState, useRef, useEffect } from 'react';
import { FiChevronsDown } from 'react-icons/fi'; // Иконка из react-icons
import './SortSettings.css';

const SortSettings = ({ onApplySort }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState(null); // Хранит текущее состояние сортировки
  const dropdownRef = useRef(null);

  const handleSortChange = (sortType) => {
    setSelectedSort(sortType); // Сохраняем выбранный тип сортировки
    onApplySort(sortType); // Применяем сортировку
    setIsDropdownOpen(false); // Закрываем выпадающий список
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Закрываем выпадающий список при клике вне его
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='catalog-settings-card' ref={dropdownRef}>
      <div className="filter-label" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        Сортировка <FiChevronsDown className="dropdown-icon" /> {selectedSort && `${selectedSort === 'cheaper' ? 'Дешевле' : 'Дороже'}`}
      </div>
      {isDropdownOpen && (
        <div className="dropdown">
          <div className="dropdown-item" onClick={() => handleSortChange('cheaper')}>
            Дешевле
          </div>
          <div className="dropdown-item" onClick={() => handleSortChange('more-expensive')}>
            Дороже
          </div>
        </div>
      )}
    </div>
  );
};

export default SortSettings;
