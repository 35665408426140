import React, { createContext, useContext, useState } from 'react';

// Создаем контекст
const AreaContext = createContext();

// Хук для использования контекста
export const useArea = () => {
  return useContext(AreaContext);
};

// Провайдер контекста
export const AreaProvider = ({ children }) => {
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [highlightedArea, setHighlightedArea] = useState(null);

  

  return (
    <AreaContext.Provider value={{ selectedAreas, setSelectedAreas, highlightedArea, setHighlightedArea }}>
      {children}
    </AreaContext.Provider>
  );
};
