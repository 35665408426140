import React from 'react';
import './PrivacyPolicy.css';
import { Link } from 'react-router-dom'; // Импортируем Link для навигации

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Политика конфиденциальности</h1>
      <p>
        Эта политика конфиденциальности описывает, как мы собираем, используем и защищаем ваши персональные данные при использовании нашего веб-сайта. Мы придаем большое значение защите ваших данных и обеспечиваем их безопасность в соответствии с действующим законодательством.
      </p>
      <h2>1. Сбор данных</h2>
      <p>
        Мы можем собирать различные типы информации, такие как имя, электронная почта, номер телефона, IP-адрес и другие данные, которые вы предоставляете нам при использовании веб-сайта.
      </p>
      <h2>2. Использование данных</h2>
      <p>
        Собранные данные используются для улучшения нашего сервиса, поддержки пользователей, маркетинговых целей и других операций, связанных с веб-сайтом. Мы не передаем ваши данные третьим лицам без вашего согласия.
      </p>
      <h2>3. Защита данных</h2>
      <p>
        Мы принимаем все разумные меры для защиты ваших данных от несанкционированного доступа, изменения или уничтожения. Однако, несмотря на наши усилия, никакая система безопасности не может гарантировать 100% безопасность.
      </p>
      <h2>4. Ваши права</h2>
      <p>
        Вы имеете право на доступ, исправление или удаление ваших персональных данных. Вы можете связаться с нами для реализации этих прав в любое время.
      </p>
      <h2>5. Изменения в политике</h2>
      <p>
        Мы можем периодически обновлять эту политику конфиденциальности. Рекомендуем вам регулярно проверять этот документ для получения актуальной информации.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
