import React, { useState, useEffect } from 'react';
import './CatalogCard.css';
import ItemCard from './ItemCard/ItemCard';
import Modal from '../../Modal/Modal.jsx';
import ReactGA from "react-ga4";

const CatalogCard = ({ filteredCards }) => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleCards, setVisibleCards] = useState(18); // По умолчанию 18 карточек
  const [loading, setLoading] = useState(true); // состояние загрузки
  const [isMobile, setIsMobile] = useState(false); // состояние для проверки мобильных устройств

  useEffect(() => {
    // Имитация загрузки данных
    setTimeout(() => {
      setLoading(false); // Устанавливаем загрузку как завершенную
    }, 2000); // Задержка в 2 секунды для демонстрации

    // Обновляем количество видимых карточек в зависимости от ширины экрана
    const updateVisibleCards = () => {
      const isNowMobile = window.innerWidth <= 768;
      if (isNowMobile !== isMobile) {
        setIsMobile(isNowMobile);
        setVisibleCards(isNowMobile ? 6 : 18); // Для мобильных устройств показываем 6 карточек
      }
    };

    // Вызываем функцию при загрузке и на каждом изменении размера экрана
    window.addEventListener('resize', updateVisibleCards);
    updateVisibleCards(); // Проверка при загрузке

    return () => {
      window.removeEventListener('resize', updateVisibleCards); // Очищаем слушатель
    };
  }, [isMobile]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'; // Блокируем прокрутку
    } else {
      document.body.style.overflow = 'auto'; // Восстанавливаем прокрутку
    }
  }, [isModalOpen]);

  const handleCardClick = (item) => {
    // Google Analytics
    ReactGA.event({
      category: "Residential complexes",
      action: "Modal window opened",
      label: item.title // optional
    });

    // Яндекс Метрика
    window.ym(98664452, 'reachGoal', 'Modal window opened');

    console.log("event: " + item.title);

    setSelectedCard(item);
    setIsModalOpen(true);
  };

  const loadMoreCards = () => {
    setVisibleCards((prevVisibleCards) => {
      const newVisibleCards = prevVisibleCards + 9;
      return Math.min(newVisibleCards, filteredCards.length); // Не показываем больше карточек, чем есть
    });
  };

  return (
    <>
      <section>
        <div className="grid-container">
          {loading ? (
            // Отображаем силуэты карточек, пока идет загрузка
            Array.from({ length: isMobile ? 6 : 18 }).map((_, index) => (
              <div key={index} className="skeleton-card"></div>
            ))
          ) : (
            filteredCards.slice(0, visibleCards).map((item) => (
              <ItemCard
                key={item.id || item.title + Math.random()} // Используйте id, а если его нет, создайте уникальный ключ
                item={item}
                onClick={() => handleCardClick(item)}
              />
            ))
          )}
        </div>
        {visibleCards < filteredCards.length && !loading && (
          <div className="load-more-container">
            <button className="load-more-button" onClick={loadMoreCards}>
              Показать ещё
            </button>
          </div>
        )}
      </section>
      {isModalOpen && (
        <Modal
          card={selectedCard}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default CatalogCard;
