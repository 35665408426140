import React, { useEffect, useState } from 'react';
import './Modal.css'; // стили для модального окна
import ModalContent from './ModalContent/ModalContent';

const Modal = ({ card, onClose }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true); // состояние загрузки видео

  useEffect(() => {
    // Проверяем ширину экрана при загрузке компонента
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }

    // Блокируем скролл на body при открытии модального окна
    document.body.style.overflow = 'hidden';

    // Восстанавливаем скролл при закрытии модального окна
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!card) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleVideoLoaded = () => {
    setVideoLoading(false); // видео загрузилось
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      {/* {videoLoading && !isMobile && (
        <div className="loader">Загрузка видео...</div> // индикатор загрузки
      )}

      {!isMobile && (
        <video
          className="modal-video"
          autoPlay
          loop
          muted
          onLoadedData={handleVideoLoaded} // видео загружено
        >
          <source src={card.backgroundVideoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )} */}

      <div className="modal-content-wrapper">
        <ModalContent card={card} onClose={onClose} /> {/* Убрали кнопку закрытия отсюда */}
      </div>
    </div>
  );
};

export default Modal;
