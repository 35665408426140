import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Hero from './components/Hero/Hero';
import Navbar from './components/navbar/Navbar';
import PrivacyPolicy from './components/StaticPages/PrivacyPolicy'; // Импортируем страницу политики
import Catalog from './components/Catalog/Catalog'; // Каталог остается на главной странице
import { AreaProvider } from './components/AreaContext';
import CookieBanner from './components/CookieBanner/CookieBanner';
import ReactGA from "react-ga4";

// Send pageview with a custom path
ReactGA.send({ 
  hitType: "pageview", 
  page: "/", 
  title: "Main page" }
);

function App() {
  return (
    <AreaProvider>
      <Router>
        <Navbar />
        <div className='container'>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero />
                  <Catalog /> {/* Каталог остается на главной странице */}
                </>
              }
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Страница политики конфиденциальности */}
          </Routes>
        </div>
        <Footer />
        <CookieBanner />
      </Router>
    </AreaProvider>
  );
}

export default App;
