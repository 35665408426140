import React, { useState, useEffect } from 'react';
import './CookieBanner.css';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Проверяем, есть ли уже согласие в localStorage
  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Сохраняем согласие в localStorage
    localStorage.setItem('cookieConsent', 'true');
    setIsVisible(false);
  };

  if (!isVisible) {
    return null; // Не показываем баннер, если согласие уже было дано
  }

  return (
    <div className="cookie-banner">
      <div className="cookie-banner-content">
        <p>
          Мы используем файлы cookie. Продолжая работу с сайтом, вы подтверждаете, что ознакомились и согласны с нашей <a href="/privacy-policy">Политикой конфиденциальности</a> и файлами Cookie.
        </p>
        <button className="accept-button" onClick={handleAccept}>
          Хорошо
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
