import React, { useState, useEffect } from 'react';
import './Navbar.css';
import Logo from '../../logo.png';
import { FaWhatsapp, FaTelegramPlane, FaPhoneAlt } from 'react-icons/fa';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav className={`nav-wrapper ${isScrolled ? 'scrolled' : ''}`}>
            <div className='nav-content'>
                {/* Используем <a> для полной перезагрузки страницы при клике на логотип */}
                <a href="/">
                    <img className={`logo ${isScrolled ? 'scrolled-logo' : ''}`} src={Logo} alt="Logo" />
                </a>
                <div className={`contact-info ${isScrolled ? 'scrolled-info' : ''}`}>
                    <FaWhatsapp className="icon" />
                    <FaTelegramPlane className="icon" />
                    <a href="tel:+74952751211" className="phone-number">
                        +7 (495) 275-12-11
                    </a>
                    <a href="tel:+74952751211" className="phone-icon">
                        <FaPhoneAlt />
                    </a> {/* Иконка телефона, кликабельная для звонка */}
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
