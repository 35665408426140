import React from 'react';
import { YMaps, Map } from 'react-yandex-maps';

const YandexMap = () => {
    console.log("Rendering YandexMap");
    return (
    <YMaps>
        <Map defaultState={{ center: [55.751574, 37.573856], zoom: 9 }} width="100%" height="500px" />
    </YMaps>
      
    );
  };
  
  export default YandexMap;
  