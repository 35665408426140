import React, { useState } from 'react';
import './Hero.css';
import SvgComponent from './SVGComponent/SvgComponent';
import RippleEffect from './Effects/RippleEffect/RippleEffect';

const Hero = ({ setSelectedArea }) => {
  const [highlightedArea, setHighlightedArea] = useState(null);

  return (
    <section className='hero-container'>
      <div className='hero-content'>
        <h2>Недвижимость в лучших <br /> жилых комплексах Москвы</h2>
        <p>Воплощаем мечты об идеальной жизни в гармоничном пространстве. Работаем со знанием дела и без комиссии.</p>
      </div>
      <div className='hero-map'>
        <SvgComponent highlightedArea={highlightedArea} setSelectedArea={setHighlightedArea} className="moscow-map" />
        <RippleEffect /> {/* Добавляем анимацию ряби */}
      </div>
    </section>
  );
};

export default Hero;
